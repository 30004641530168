"use strict";

var footer_map;
jQuery(document).ready(function($) {
    var lat = jQuery('#footer_map_lat').val();
    var lng = jQuery('#footer_map_lng').val();
    var html_content = jQuery('#footer_map_content').html();
    // var html_content = 'Northern Laundry Services LLC';
    console.log(lat, lng, html_content);
    footer_map = new GMaps({
        div: '#footer_map',
        lat: lat,
        lng: lng,
        scrollwheel: false,
        setZoom: 0
    });

    footer_map.addMarker({
        lat: lat,
        lng: lng,
        infoWindow: {
            content: html_content,
            maxWidth: 300
        }
    });
    google.maps.event.trigger(footer_map.markers[0], 'click');
    new WOW().init();
});




